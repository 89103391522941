@media screen and (max-width: 900px) {
  body #Home {
    padding-top: 7rem;
  }
  body #Home #category-container {
    -ms-grid-columns: (minmax(0, 1fr))[2];
        grid-template-columns: repeat(2, minmax(0, 1fr));
    border-top-width: 0;
  }
  body #Home #category-container img {
    height: 15rem;
  }
  body #Home #category-container div[class="flex gap-4"] {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 0.2rem;
  }
  body #Home #category-container div[class="flex gap-4"] > h3 {
    margin-bottom: 0;
  }
  body #header-lg {
    display: none;
  }
  body #sub-category-main-div {
    overflow-x: scroll;
  }
  body #sub-category-main-div > div {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  body #sub-category-main-div .categoryHeaderMenu {
    height: auto;
  }
  body #cart-sidebar {
    padding-top: 6rem;
  }
  body #cart-sidebar #cart-sidebar-body {
    height: calc(100% - 6rem) !important;
  }
  body #wishlist-sidebar {
    padding-top: 6rem;
  }
  body #wishlist-sidebar #wishlist-sidebar-body {
    height: calc(100% - 6rem) !important;
  }
  body #login-page {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  body #login-page #image-container {
    -webkit-box-flex: 0.2;
        -ms-flex: 0.2;
            flex: 0.2;
    border: none;
    margin-top: 7rem;
  }
  body #login-page #login-form {
    display: block;
    min-height: auto;
  }
  body #login-page #login-form > div {
    width: auto;
    padding-top: 0;
    margin-top: 0;
  }
  body #registration-page #registration-form-container {
    margin-top: 6rem;
    min-height: auto;
    display: block;
  }
  body #registration-page #registration-form-container > div {
    width: auto;
  }
  body #registration-page #img-container {
    display: none;
  }
  body #crousel-page #crousel {
    /* height: 10rem; */
    padding: 0;
  }
  body #crousel-page button {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    color: #fff;
  }
  body .product-page {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 6rem;
  }
  body .product-page #image-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    height: 50vh !important;
  }
  body .product-page #description-container .first-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  body .product-page #description-container .first-row #name_and_brand {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }
  body .product-page #description-container .first-row #name_and_brand h1 {
    font-size: 1rem;
  }
  body .product-page #description-container .first-row #name_and_brand h1:nth-child(even) {
    margin-bottom: 1rem;
  }
  body .product-page #description-container .first-row #bidding-time {
    margin-bottom: 1rem;
  }
  body .product-page #description-container .first-row #bidding-time .custom-label {
    font-size: 1rem;
    margin-left: 0;
  }
  body .product-page #description-container .first-row #bidding-time #countdown {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.7rem 2rem;
  }
  body .product-page #description-container .first-row #bidding-time #countdown h1 {
    font-size: 0.8rem;
  }
  body .product-page #description-container .second-row {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  body .product-page #description-container .second-row-discount span:nth-child(odd) {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  body .product-page #description-container .second-row-discount span:nth-child(even) {
    font-size: 1rem;
  }
  body .product-page #description-container .third-row {
    font-size: 1rem;
  }
  body .product-page #description-container .sixth-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: block;
    margin-top: 1rem;
  }
  body .product-page #description-container .sixth-row > div input {
    width: 45%;
  }
  body .product-page #description-container .sixth-row > div button {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    width: 60%;
  }
  body .product-page #description-container .ninth-row input {
    width: 70%;
  }
  body .product-page #description-container .ninth-row button {
    width: 30%;
    margin-left: 1rem;
  }
  body .product-page #description-container .eleventh-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  body .product-list-page #product-grid {
    -ms-grid-columns: (minmax(0, 1fr))[1];
        grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  body .checkout-page {
    display: block;
  }
  body .checkout-page #delivery-address {
    display: block;
  }
  body .checkout-page #product-detail #sub {
    display: block;
  }
  body .checkout-page #product-detail #sub h2 {
    padding-left: 0;
  }
  body .checkout-page #product-detail #sub #delivrBy {
    display: block;
  }
  body .checkout-page #product-detail #quntity {
    padding-left: 0 !important;
  }
  body .checkout-page #input-container {
    padding: 0;
    margin: 0;
    margin-top: 3rem;
  }
  body .checkout-page #input-container #coupon-code {
    display: block;
  }
  body .checkout-page #input-container #coupon-code input {
    padding: 0.5rem;
    width: 75%;
  }
  body .checkout-page #input-container #coupon-code button {
    width: 75%;
    margin: 0;
    margin-top: 1rem;
  }
  body #profile-page {
    margin-top: 7rem;
    padding: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  body #profile-page #personalInfoPassword input,
  body #profile-page #personalInfoPassword button,
  body #profile-page #personalInfoMobile input,
  body #profile-page #personalInfoMobile button {
    width: 100%;
  }
  body #profile-page #personalInfoEmail input {
    width: 100%;
  }
  body #profile-page #manageAddressPage div[class="flex flex-col flex-wrap h-72"] {
    height: auto;
  }
  body #profile-page #manageAddressPage div[class="flex flex-col flex-wrap h-72"] label {
    width: 50%;
  }
  body #profile-page #manageAddressPage .autocomplete-type-ahead > div input[role="combobox"] {
    width: 100%;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  }
  body #profile-page #manageAddressPage .autocomplete-type-ahead > div[aria-label="menu-options"] {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-left: 0.5rem;
    z-index: auto;
  }
  body #profile-page #manageAddressPage .autocomplete-type-ahead #svfvdfvddv {
    background-color: #fff;
  }
  body #profile-page #panInfoPage input[type="text"],
  body #profile-page #panInfoPage input[type="file"] {
    width: 100%;
  }
  body #orders-product-page {
    width: 100% !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  body #orders-product-page #btn-cont > div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: 900px) {
  #header-md {
    display: none;
  }
}

.autocomplete-type-ahead > div input[role="combobox"] {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.autocomplete-type-ahead > div[aria-label="menu-options"] {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: auto;
}

.autocomplete-type-ahead #svfvdfvddv,
.autocomplete-type-ahead #svfvddewedvddv {
  background-color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dragAnaDropDiv div[role="button"] {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.dragAnaDropDiv label {
  max-width: inherit;
  height: 100%;
}

.dragAnaDropDiv label svg {
  display: none;
}

.dragAnaDropDiv label > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.dragAnaDropDiv label > div .file-types {
  display: none;
}
