@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header__profile_dropdown_container:hover .header__profile_dropdown_btn {
  border: 1px solid rgb(226 232 240);
  border-radius: 4px;
}

.header__profile_dropdown_container:hover .header__profile_dropdown_icon {
  transform: rotate(180deg);
}

.header__profile_dropdown_container:hover .header__profile_dropdown_btn + .header__profile_dropdown {
  display: block;
}
