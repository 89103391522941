.categoryHeaderDropdown {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0px 0px;
    z-index: 1;
    border-radius: 4px;
    li {
        padding-left: 16px;
        padding-right: 16px;
    }
    li:hover {
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    }
}

.categoryHeaderMenu:hover .categoryHeaderDropdown {
    display: block;
}