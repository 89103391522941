// $small: 300px;
// $medium: 900px;
// @media screen and (max-width: $medium) {
//     body {
//         min-width: fit-content;
//         #header {
//             width: 100%;
//             #header-gutter {
//                 min-width: 2rem;
//             }
//         }
//         #category-container {
//             grid-template-columns: auto auto auto;
//             border-top-width: 0;
//             div[class="group relative"] {
//                 img {
//                     height: 30rem;
//                 }
//             }
//         }
//         #footer {
//             &>div {
//                 display: flex;
//                 #about,
//                 #help,
//                 #policy,
//                 #office {
//                     width: 30%;
//                 }
//             }
//         }
//         #product-body {
//             width: 100%;
//         }
//     }
// }
$small: 300px;
$medium: 900px;
$font-size-sm:0.8rem;
$font-size-md:1rem;
$font-size-lg:1.5rem;
$bottom-gutter-md:1rem;
@media screen and (max-width: $medium) {
    body {
        #Home {
            padding-top: 7rem;
            #category-container {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                border-top-width: 0;
                img {
                    height: 15rem;
                }
                div[class="flex gap-4"] {
                    flex-wrap: wrap;
                    row-gap: 0.2rem;
                    &>h3 {
                        margin-bottom: 0;
                    }
                }
            }
        }
        #header-lg {
            display: none;
        }
        #header-md {
            overflow: hidden;
            padding: 0.5rem;
            #header-gutter {
                min-width: 0.5rem;
            }
        }
        #sub-category-main-div {
            overflow: scroll;
            &>div {
                justify-content: flex-start;
            }
            .categoryHeaderMenu {
                height: auto;
            }
        }
        #cart-sidebar {
            padding-top: 6rem;
            #cart-sidebar-body {
                height: calc(100% - 6rem) !important;
            }
        }
        #wishlist-sidebar {
            padding-top: 6rem;
            #wishlist-sidebar-body {
                height: calc(100% - 6rem) !important;
            }
        }
        #login-page {
            flex-direction: column;
            #image-container {
                flex: 0.2;
                border: none;
                margin-top: 5rem;
            }
            #login-form {
                display: block;
                min-height: auto;
                &>div {
                    width: auto;
                    padding-top: 0;
                    margin-top: 0;
                }
            }
        }
        #registration-page {
            #registration-form-container {
                margin-top: 6rem;
                min-height: auto;
                display: block;
                &>div {
                    width: auto;
                }
            }
            #img-container {
                display: none;
            }
        }
        #crousel-page {
            #crousel {
                // height: 10rem;
                padding: 0;
            }
            button {
                background-color: rgba(0, 0, 0, 0.5);
                height: 100%;
                color: #fff;
            }
        }
        .product-page {
            flex-direction: column;
            margin-top: 6rem;
            #image-container {
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                height: 50vh !important;
            }
            #description-container {
                .first-row {
                    flex-direction: column;
                    #name_and_brand {
                        max-width: fit-content !important;
                        h1 {
                            font-size: $font-size-md;
                        }
                        h1:nth-child(even) {
                            margin-bottom: $bottom-gutter-md;
                        }
                    }
                    #bidding-time {
                        margin-bottom: $bottom-gutter-md;
                        .custom-label {
                            font-size: $font-size-md;
                            margin-left: 0;
                        }
                        #countdown {
                            width: fit-content;
                            padding: 0.7rem 2rem;
                            h1 {
                                font-size: $font-size-sm;
                            }
                        }
                    }
                }
                .second-row {
                    font-size: $font-size-lg;
                    margin-right: 0.5rem;
                }
                .second-row-discount {
                    span:nth-child(odd) {
                        font-size: $font-size-lg;
                        margin-right: 0.5rem;
                    }
                    span:nth-child(even) {
                        font-size: $font-size-md;
                    }
                }
                .third-row {
                    font-size: $font-size-md;
                }
                .fourth-row {
                    margin-top: $bottom-gutter-md;
                    display: block;
                    h1 {
                        margin-bottom: $bottom-gutter-md;
                    }
                }
                .sixth-row {
                    flex-direction: column;
                    display: block;
                    margin-top: 1rem;
                    &>div {
                        input {
                            width: 45%;
                        }
                        button {
                            margin-left: 0.5rem;
                            font-size: $font-size-sm;
                            width: 60%;
                        }
                    }
                }
                .ninth-row {
                    input {
                        width: 70%;
                    }
                    button {
                        width: 30%;
                        margin-left: $bottom-gutter-md;
                    }
                }
                .eleventh-row {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }
        .product-list-page {
            #product-grid {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                img {
                    // width: 100%;
                    // max-height: fit-content;
                }
            }
        }
        .checkout-page {
            display: block;
            #delivery-address {
                display: block;
            }
            #product-detail {
                #sub {
                    display: block;
                    h2 {
                        padding-left: 0;
                    }
                    #delivrBy {
                        display: block;
                    }
                }
                #quntity {
                    padding-left: 0 !important;
                }
            }
            #input-container {
                padding: 0;
                margin: 0;
                margin-top: 3rem;
                #coupon-code {
                    display: block;
                    input {
                        padding: 0.5rem;
                        width: 75%;
                    }
                    button {
                        width: 75%;
                        margin: 0;
                        margin-top: 1rem;
                    }
                }
            }
        }
        #profile-page {
            margin-top: 7rem;
            padding: 1rem;
            flex-direction: column;
            #personalInfoPassword,
            #personalInfoMobile {
                input,
                button {
                    width: 100%;
                }
            }
            #personalInfoEmail {
                input {
                    width: 100%;
                }
            }
            #manageAddressPage {
                div[class="flex flex-col flex-wrap h-72"] {
                    height: auto;
                    label {
                        width: 50%;
                    }
                }
                .autocomplete-type-ahead {
                    &>div {
                        input[role="combobox"] {
                            width: 100%;
                            padding: 0.5rem 0.75rem 0.5rem 0.75rem;
                        }
                    }
                    &>div[aria-label="menu-options"] {
                        display: flex !important;
                        flex-direction: column;
                        padding-left: 0.5rem;
                        z-index: auto;
                    }
                    #svfvdfvddv {
                        background-color: #fff;
                    }
                }
            }
            #panInfoPage {
                input[type="text"],
                input[type="file"] {
                    width: 100%;
                }
            }
        }
        #orders-product-page {
            width: 100% !important;
            flex-direction: column;
            #btn-cont {
                &>div {
                    flex-direction: column-reverse !important;
                }
            }
        }
    }
}

@media screen and (min-width: $medium) {
    #header-lg {}
    #header-md {
        display: none;
    }
}

.autocomplete-type-ahead {
    &>div {
        input[role="combobox"] {
            width: 100%;
            padding: 0.5rem 0.75rem 0.5rem 0.75rem;
        }
    }
    &>div[aria-label="menu-options"] {
        display: flex !important;
        flex-direction: column;
        // padding-left: 0.5rem;
        z-index: auto;
    }
    #svfvdfvddv,
    #svfvddewedvddv {
        background-color: #fff;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.dragAnaDropDiv {
    div[role="button"] {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    label {
        max-width: inherit;
        height: 100%;
        svg {
            display: none;
        }
        &>div {
            display: flex;
            justify-content: center;
            .file-types {
                display: none;
            }
        }
    }
}